.c-home-page__log-in-buttons {
  display: flex;
  gap: var(--g-spacing);
}

@media (max-width: 75em) {
  .c-home-page__log-in-buttons {
    flex-direction: column;
  }
}
